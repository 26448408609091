import React from 'react';
import './layout-footer.scss';
import { LogoBrand } from './logo-brand';
import { InstagramIcon } from '../../Icons/instagram-icon';
import { TwitterIcon } from '../../Icons/twitter-icon';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../../Constants';
import { LinkedinIcon } from '../../Icons/linkedin-icon';
import TiktokIcon from '../../Icons/tiktok-icon';
import YoutubeIcon from '../../Icons/youtube-icon';
import useCustomNavigation from '../../Hooks/use-custom-navigation';
import { useStores } from '../../Hooks/use-stores';

const LayoutFooter = () => {
    const { t } = useTranslation();
    const nav = useCustomNavigation();
    const { cookiesStore } = useStores();

    const onClickModalSettings = () => {
        cookiesStore.modalIsVisible = true;
    };

    return (
        <footer className="LayoutFooter">
            <div className="top-section">
                <div className="cursor-pointer" onClick={() => nav(CONSTANTS.SCREEN.HOME)}>
                    <LogoBrand />
                </div>
                <div className="links-container">
                    <div
                        className="cursor-pointer text-body-primary-font"
                        onClick={() => nav(CONSTANTS.SCREEN.CONTACT_US)}
                    >
                        {t('nav_contact_us')}
                    </div>
                    <div
                        className="cursor-pointer text-body-primary-font"
                        onClick={() => nav(CONSTANTS.SCREEN.TERMS_OF_SERVICE)}
                    >
                        {t('nav_terms_of_service')}
                    </div>
                    <div
                        className="cursor-pointer text-body-primary-font"
                        onClick={() => nav(CONSTANTS.SCREEN.PRIVACY_NOTICE)}
                    >
                        {t('nav_privacy_policy')}
                    </div>
                    <div
                        className="cursor-pointer text-body-primary-font"
                        onClick={onClickModalSettings}
                    >
                        {t('coookie_modal_navigation')}
                    </div>
                </div>

                <div className="social-media-container">
                    <a href={CONSTANTS.INSTAGRAM_URL} target="_blank" rel="noreferrer">
                        <InstagramIcon />
                    </a>
                    <a href={CONSTANTS.LINKEDIN_URL} target="_blank" rel="noreferrer">
                        <LinkedinIcon fill="black" />
                    </a>
                    <a href={CONSTANTS.TWITTER_URL} target="_blank" rel="noreferrer">
                        <TwitterIcon />
                    </a>
                    <a href={CONSTANTS.TIKTOK_URL} target="_blank" rel="noreferrer">
                        <TiktokIcon />
                    </a>
                    <a href={CONSTANTS.YOUTUBE_URL} target="_blank" rel="noreferrer">
                        <YoutubeIcon />
                    </a>
                </div>
            </div>

            <div className="bottom-section text-white">
                <div className="inner">
                    <span style={{ textAlign: 'center' }}>
                        {t('copyright_footer')} {new Date().getFullYear()}
                    </span>
                </div>
            </div>
        </footer>
    );
};

export default LayoutFooter;
