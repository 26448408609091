import React, { useState, useRef, TouchEvent } from 'react';
import './index.scss';
import CardSustainabillity from './components/card-sustainabillity/card';
import { ThreeSubIcon } from '../../../../Icons/three-sub-icon';
import { PackageIcon } from '../../../../Icons/package-icon';
import { ScaleIcon } from '../../../../Icons/scale-icon';
import { UserSubstaIcon } from '../../../../Icons/user-substa-icon';
import { TreeIcon } from '../../../../Icons/tree-icon';
import { HandHeartIcon } from '../../../../Icons/hand-heart-icon';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../Style/theme';
import { ChevronRightIcon } from '../../../../Icons/chevron-right-icon';
import { ChevronLeftIcon } from '../../../../Icons/chevron-left-icon';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import WrapperSectionTitle from '../../../../Components/WrapperSection/title';
import Copydeck from '../../../../i18n/Copydeck';

const ImpactAndInclusionsCardsSection: React.FC = () => {
    const { t } = useTranslation();
    const [position, setPosition] = useState([
        'card-position-1',
        'card-position-2',
        'card-position-3',
        'card-position-4',
        'card-position-5',
        'card-position-6',
    ]);
    const [activeCurrentAnimationDirection, setActiveCurrentAnimationDirection] = useState('none');
    const [disabled, setDisabled] = useState(false);
    const [forceToHide, setForceToHide] = useState(false);
    const { windowWidth } = useWindowDimensions();

    const contentRef = useRef<HTMLDivElement>(null);
    const [touchStart, setTouchStart] = useState<{ x: number; y: number } | null>(null);
    const [touchEnd, setTouchEnd] = useState<{ x: number; y: number } | null>(null);

    // Minimum swipe distance (in pixels)
    const minSwipeDistance = 50;

    const rotateArrayLeft = (arr: string[]) => {
        const first = arr.shift();
        if (first) {
            arr.push(first);
        }
        return [...arr];
    };

    const rotateArrayRight = (arr: string[]) => {
        const last = arr.pop();
        if (last) {
            arr.unshift(last);
        }
        return [...arr];
    };

    const onClickLeft = () => {
        if (!disabled) {
            handleSwipe('right');
        }
    };

    const onClickRight = () => {
        if (!disabled) {
            handleSwipe('left');
        }
    };

    const handleSwipe = (direction: 'left' | 'right') => {
        setForceToHide((prev) => !prev);
        setActiveCurrentAnimationDirection(direction);
        setDisabled(true);

        const timeout = setTimeout(() => {
            setActiveCurrentAnimationDirection('none');
            setPosition((prevPosition) => {
                return direction === 'left'
                    ? rotateArrayRight([...prevPosition])
                    : rotateArrayLeft([...prevPosition]);
            });
            setDisabled(false);
        }, 1500);

        return () => {
            clearTimeout(timeout);
        };
    };

    const onTouchStart = (e: TouchEvent) => {
        setTouchEnd(null);
        setTouchStart({
            x: e.targetTouches[0].clientX,
            y: e.targetTouches[0].clientY,
        });
    };

    const onTouchMove = (e: TouchEvent) => {
        if (windowWidth > 768) return;
        setTouchEnd({
            x: e.targetTouches[0].clientX,
            y: e.targetTouches[0].clientY,
        });
    };

    const onTouchEnd = (e: TouchEvent) => {
        if (windowWidth > 768 || !touchStart || !touchEnd) return;

        const distanceX = touchStart.x - touchEnd.x;
        const distanceY = touchStart.y - touchEnd.y;
        const isHorizontalSwipe = Math.abs(distanceX) > Math.abs(distanceY);

        if (isHorizontalSwipe && Math.abs(distanceX) > minSwipeDistance) {
            if (distanceX > 0) {
                onClickRight();
            } else {
                onClickLeft();
            }

            e.preventDefault(); // Prevent vertical scroll
        }
    };

    return (
        <div className="ImpactAndInclusionsCardsSection">
            <div style={{ textAlign: 'center', paddingTop: windowWidth <= 700 ? 80 : 120 }}>
                <WrapperSectionTitle>
                    <div className="title-background gradient-text">
                        {Copydeck.impactsAndInclusionsImpactInclusionParallax}
                    </div>
                </WrapperSectionTitle>
            </div>
            <div
                ref={contentRef}
                className="content"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                <CardSustainabillity
                    className={`${position[0]} -animation-${activeCurrentAnimationDirection}`}
                    item={{
                        icon: () => <HandHeartIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_1_sustainabillity'),
                        description: t('CardsSustainability.desc_card_1_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_1_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-1.png'),
                    }}
                    forceToHide={forceToHide}
                />
                <CardSustainabillity
                    className={`${position[1]} -animation-${activeCurrentAnimationDirection}`}
                    item={{
                        icon: () => <TreeIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_2_sustainabillity'),
                        description: t('CardsSustainability.desc_card_2_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_2_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-2.png'),
                    }}
                    forceToHide={forceToHide}
                />
                <CardSustainabillity
                    className={`${position[2]} -animation-${activeCurrentAnimationDirection}`}
                    item={{
                        icon: () => <UserSubstaIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_3_sustainabillity'),
                        description: t('CardsSustainability.desc_card_3_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_3_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-6.png'),
                    }}
                    forceToHide={forceToHide}
                />
                <CardSustainabillity
                    className={`${position[3]} -animation-${activeCurrentAnimationDirection}`}
                    item={{
                        icon: () => <ThreeSubIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_4_sustainabillity'),
                        description: t('CardsSustainability.desc_card_4_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_4_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-3.png'),
                    }}
                    forceToHide={forceToHide}
                />
                <CardSustainabillity
                    className={`${position[4]} -animation-${activeCurrentAnimationDirection}`}
                    item={{
                        icon: () => <PackageIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_5_sustainabillity'),
                        description: t('CardsSustainability.desc_card_5_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_5_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-4.png'),
                    }}
                    forceToHide={forceToHide}
                />
                <CardSustainabillity
                    className={`${position[5]} -animation-${activeCurrentAnimationDirection}`}
                    item={{
                        icon: () => <ScaleIcon width={50} height={50} fill={theme['white']} />,
                        title: t('CardsSustainability.title_card_6_sustainabillity'),
                        description: t('CardsSustainability.desc_card_6_sustainabillity'),
                        longDescription: t('CardsSustainability.long_desc_card_6_sustainabillity'),
                        backgroundImage: require('./../../../../Assets/Img/ImpactsAndInclusions/cards/card-5.png'),
                    }}
                    forceToHide={forceToHide}
                />
            </div>

            <div className="container-ctas">
                <div className="content-cta">
                    <div
                        className={`cta -left ${disabled ? '-disabled' : ''}`}
                        onClick={onClickLeft}
                    >
                        <ChevronLeftIcon fill={theme['white']} width={20} height={20} />
                    </div>
                    <div
                        className={`cta -right ${disabled ? '-disabled' : ''}`}
                        onClick={onClickRight}
                    >
                        <ChevronRightIcon fill={theme['white']} width={20} height={20} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImpactAndInclusionsCardsSection;
